// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .GeneralProductsContainer-general-box {
    color: rgba(255, 255, 255, 0.946);
    min-height: calc(100vh - 72px);
    
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    display: flex;
    width: 100%;
    background-color: black;
}

body .GeneralProductsContainer-general-box img {}


body .GeneralProductsContainer-grid-container {
    padding: 3% 8% 2% 8%;
    background-color: #22224ff6;
    display: flex;
    align-content: flex-start;

}

body .GeneralProductsContainer-title {
    font-size: 230%;
    font-weight: 700;
}

body .GeneralProductsContainer-subtitle {
    letter-spacing: 7.6px;
    font-size: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 1%;
}

body .GeneralProductsContainer-card {
    justify-content: center;
    display: flex;
    padding: 2%;
}`, "",{"version":3,"sources":["webpack://./src/containers/GeneralProductsContainer/GeneralProductsContainer.css"],"names":[],"mappings":"AAEA;IACI,iCAAiC;IACjC,8BAA8B;;IAE9B,iCAAiC;IACjC,gBAAgB;IAChB,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,WAAW;IACX,uBAAuB;AAC3B;;AAEA,gDAAgD;;;AAGhD;IACI,oBAAoB;IACpB,2BAA2B;IAC3B,aAAa;IACb,yBAAyB;;AAE7B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,iCAAiC;IACjC,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,WAAW;AACf","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n\nbody .GeneralProductsContainer-general-box {\n    color: rgba(255, 255, 255, 0.946);\n    min-height: calc(100vh - 72px);\n    \n    font-family: 'Roboto', sans-serif;\n    overflow: hidden;\n    background-size: cover;\n    background-position: center;\n    display: flex;\n    width: 100%;\n    background-color: black;\n}\n\nbody .GeneralProductsContainer-general-box img {}\n\n\nbody .GeneralProductsContainer-grid-container {\n    padding: 3% 8% 2% 8%;\n    background-color: #22224ff6;\n    display: flex;\n    align-content: flex-start;\n\n}\n\nbody .GeneralProductsContainer-title {\n    font-size: 230%;\n    font-weight: 700;\n}\n\nbody .GeneralProductsContainer-subtitle {\n    letter-spacing: 7.6px;\n    font-size: 100%;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 600;\n    text-transform: uppercase;\n    padding-bottom: 1%;\n}\n\nbody .GeneralProductsContainer-card {\n    justify-content: center;\n    display: flex;\n    padding: 2%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
