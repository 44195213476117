// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .ProductCard-box-container {
  min-width: 313px;
  min-height: 598px;
  max-width: 313px;
  display: flex;
  justify-content: center;
}

body .ProductCard-card-actions-container {
  display: flex;
  align-items: flex-end;
}

body .ProductCard-paper-container {
  height: -webkit-fill-available;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductCards/ProductCard/ProductCard.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["body .ProductCard-box-container {\n  min-width: 313px;\n  min-height: 598px;\n  max-width: 313px;\n  display: flex;\n  justify-content: center;\n}\n\nbody .ProductCard-card-actions-container {\n  display: flex;\n  align-items: flex-end;\n}\n\nbody .ProductCard-paper-container {\n  height: -webkit-fill-available;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
