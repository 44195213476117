// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .ProductCardsContainer-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 2% 10% 2% 10%;
  min-width: 313px;
}

body .ProductCardsContainer-title {
  font-size: 57px;
  font-weight: 700;
  text-align: center;
}

body .ProductCardsContainer-subtitle {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

body .ProductCardsContainer-grid-item-card {
  display: flex;
  padding-top: 2%;
  justify-content: center;
}

body .ProductCardsContainer-grid-container-item {
  justify-content: center;
}

body .ProductPicker-ProductCardsContainer-skeleton {
  min-width: "250px";
  min-height: "546px";
}

body .ProductCardsContainer-autocomplete-yards-label{
  color: #2a2b2c;
}

/* body . {}


body . {}


body . {}


body . {}


body . {}


body . {}


body . {} */
`, "",{"version":3,"sources":["webpack://./src/containers/ChassisProductsContainer/ChassisProductsContainer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;;;;;;;;;;;;;;;;;;WAkBW","sourcesContent":["body .ProductCardsContainer-box-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  padding: 2% 10% 2% 10%;\n  min-width: 313px;\n}\n\nbody .ProductCardsContainer-title {\n  font-size: 57px;\n  font-weight: 700;\n  text-align: center;\n}\n\nbody .ProductCardsContainer-subtitle {\n  font-size: 18px;\n  font-weight: 500;\n  text-align: center;\n}\n\nbody .ProductCardsContainer-grid-item-card {\n  display: flex;\n  padding-top: 2%;\n  justify-content: center;\n}\n\nbody .ProductCardsContainer-grid-container-item {\n  justify-content: center;\n}\n\nbody .ProductPicker-ProductCardsContainer-skeleton {\n  min-width: \"250px\";\n  min-height: \"546px\";\n}\n\nbody .ProductCardsContainer-autocomplete-yards-label{\n  color: #2a2b2c;\n}\n\n/* body . {}\n\n\nbody . {}\n\n\nbody . {}\n\n\nbody . {}\n\n\nbody . {}\n\n\nbody . {}\n\n\nbody . {} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
