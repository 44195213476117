import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Cookies from "universal-cookie";
import ProductCard from "../../components/ProductCards/ProductCard/ProductCard";
import "./ProductCardsContainer.css";
import { Skeleton, Paper, TextField, Autocomplete } from "@mui/material";
import { getPrices } from "../../services/priceService";

const ProductCardsContainer = () => {
  const cookies = new Cookies();
  const [prices, setPrices] = React.useState([]);
  const [listOfYardsNames, setListOfYardsNames] = React.useState([]);
  const [originalPrices, setOriginalPrices] = React.useState([]);
  const [listOfYardsFiltered, setListOfYardsFiltered] = React.useState([]);
  React.useEffect(() => {
    if (
      cookies.get("sessionCosmos")?.type === "YardOwner" &&
      !cookies.get("sessionCosmos")?.email[0].includes("snecorp")
    ) {
      window.location.href = "/yms-admin/owner-yards";
    }
    getPrices({ ScacCode: "" }).then((prices) => {
      setOriginalPrices(prices);
      setPrices(prices);
      const listOfYards = prices.map((item) => item.YardDisplayName);

      setListOfYardsNames(listOfYards);
    });
  }, []);

  function filterYards() {
    if (listOfYardsFiltered.length === 0) {
      setPrices(originalPrices);
      return;
    }
    const filteredPrices = originalPrices.filter((item) =>
      listOfYardsFiltered.includes(item.YardDisplayName)
    );
    setPrices(filteredPrices);
  }

  React.useEffect(() => {
    filterYards();
  }, [listOfYardsFiltered]);
  return (
    <Box className="ProductCardsContainer-box-container">
      <Typography color="text.primary" className="ProductCardsContainer-title">
        What yard do you need?
      </Typography>
      <Typography
        color="text.primary"
        className="ProductCardsContainer-subtitle"
      >
        Choose the yard that best fits your needs.
      </Typography>{" "}
      {/* <Autocomplete
        multiple
        disabled={listOfYardsNames.length === 0}
        id="tags-outlined"
        getOptionLabel={(option) => {
          return option;
        }}
        options={listOfYardsNames}
        filterSelectedOptions
        onChange={(event, value) => {
          setListOfYardsFiltered(value);
        }}
        sx={{ width: "80%", padding: 2 }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              className: "ProductCardsContainer-autocomplete-yards-search",
            }}
            label="Filter Yard Names"
          />
        )}
      /> */}
      <Grid container className="ProductCardsContainer-grid-container-item">
        {prices.length > 0 ? (
          prices.map((item, index) => (
            <Grid
              item
              key={index}
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="ProductCardsContainer-grid-item-card"
            >
              <ProductCard key={index} index={index} item={item} />
            </Grid>
          ))
        ) : (
          <>
            {[...Array(3)].map((_, index) => (
              <Grid
                item
                key={index}
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                className="ProductCardsContainer-grid-item-card"
              >
                <Paper elevation={4} className="ProductCardsContainer-paper">
                  <Skeleton
                    key={index}
                    width={313}
                    height={598}
                    animation="wave"
                    style={{ borderRadius: 25 }}
                    variant="rounded"
                  />
                </Paper>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ProductCardsContainer;
