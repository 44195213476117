// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .ProductCard-box-container {
    min-width: 313px;
    min-height: 598px;
}

body .ProductCard-card-actions-container {
    display: flex;
    align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/ChassisCards/ProductCard/ProductCard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB","sourcesContent":["body .ProductCard-box-container {\n    min-width: 313px;\n    min-height: 598px;\n}\n\nbody .ProductCard-card-actions-container {\n    display: flex;\n    align-items: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
