// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* global.css */

/* Reset default browser styles */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
}

body .ProductPicker-container-root {
    min-height: calc(100vh - 72px);
    padding: 0;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf,iCAAiC;AACjC;;;;;;;;;;IAUI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,8BAA8B;IAC9B,UAAU;IACV,WAAW;AACf","sourcesContent":["/* global.css */\n\n/* Reset default browser styles */\nbody,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\nol,\nul {\n    margin: 0;\n    padding: 0;\n}\n\nbody .ProductPicker-container-root {\n    min-height: calc(100vh - 72px);\n    padding: 0;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
