import React from "react";
import Typography from "@mui/material/Typography";

const DayPrice = ({ cost }) => {
  const formatCost = (cost) => {
    if (!cost) {
      return "0.00";
    }

    const costString = cost.toString();
    const [dollars, cents] = costString.split(".");
    return `${dollars}.${cents || "00"}`;
  };

  const formattedCost = formatCost(cost);

  return (
    <>
      <Typography
        sx={{ mt: 1 }}
        color="text.primary"
        style={{
          fontSize: 30,
          fontWeight: "bold",
        }}
      >
        $
      </Typography>
      <Typography
        color="text.primary"
        style={{
          fontSize: 57,
          fontWeight: "bold",
        }}
      >
        {formattedCost.split(".")[0]}
      </Typography>
      <Typography
        color="text.primary"
        style={{
          fontSize: 20,
          fontWeight: "bold",
        }}
        sx={{ mb: 2, alignSelf: "flex-end" }}
      >
        .{formattedCost.split(".")[1]}
      </Typography>
      <Typography
        color="text.disabled"
        style={{
          fontSize: 15,
          fontWeight: "medium",
        }}
        sx={{ mb: "17px", ml: "6px", alignSelf: "flex-end" }}
      >
        /Day
      </Typography>
    </>
  );
};

export default DayPrice;
