import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import "./GeneralProductsContainer.css";
import Cookies from "universal-cookie";
import DisabledProductCard from "../../components/GeneralCards/DisabledProduct/DisabledProductCard";
import AvailableProduct from "../../components/GeneralCards/AvaialbleProduct/AvailableProductCard";
const GeneralProductsContainer = () => {
  const cookies = new Cookies();
  function pushNewUrlToWindow(newUrl) {
    window.history.pushState({}, "", newUrl);
  }
  function handleEvaluateIfIsYardOwner() {
    const sessionCosmos = cookies.get("sessionCosmos");
    if (
      sessionCosmos &&
      sessionCosmos.type === "YardOwner" &&
      !sessionCosmos.email[0].includes("snecorp")
    ) {
      return "/yms-admin/owner-yards";
    } else {
      return "/landing-page/products/yard-storage";
    }
  }
  return (
    <Box
      className="GeneralProductsContainer-general-box"
      style={{
        backgroundImage: `url(${
          process.env.REACT_APP_IMAGES_URL + "GeneralBackground.png"
        })`, // Aquí se usa la URL de la imagen de fondo
      }}
    >
      <Grid container className="GeneralProductsContainer-grid-container">
        <Grid item xs={12}>
          <Typography variant="h2" className="GeneralProductsContainer-title">
            Products
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className="GeneralProductsContainer-subtitle"
          >
            Management System - Yard Storage - Chassis Rental
          </Typography>
        </Grid>
        <Grid
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className="GeneralProductsContainer-card"
        >
          <AvailableProduct
            product={"LIST YOUR YARD AND EQUIPMENT WITH US!"}
            productDescription={[
              "No transaction fees",
              "Accurate inventory",
              "Secure payments",
            ]}
            functionToUse={pushNewUrlToWindow}
            urlOfProduct={"/yms-admin/create-yard-owner"}
            imageProduct={"SystemManagement.jpg"}
          />
        </Grid>
        <Grid
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className="GeneralProductsContainer-card"
        >
          <AvailableProduct
            product={"Rent Yard Space with DrayPower!"}
            productDescription={[
              "Flexible rental options",
              "User friendly platform",
              "Dedicated support team",
            ]}
            functionToUse={pushNewUrlToWindow}
            urlOfProduct={handleEvaluateIfIsYardOwner()}
            imageProduct={"YardStorage.jpg"}
          />
        </Grid>
        <Grid
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className="GeneralProductsContainer-card"
        >
          <AvailableProduct
            product={"Rent Chassis with DrayPower!"}
            productDescription={[
              "Wide selection of equipment",
              "Real time availability",
              "Flexible rental options",
            ]}
            functionToUse={pushNewUrlToWindow}
            urlOfProduct={"/landing-page/products/chassis-rental"}
            imageProduct={"Chassis.jpg"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default GeneralProductsContainer;
