import * as React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import DayPrice from "../DayPrice/DayPrice";
import PriceItemList from "../PriceItemList/PriceItemList";
import YardFeatures from "../YardFeatures/YardFeatures";
const PricesSection = ({ services, selectedOption, itemYard }) => {
  // Filtrar el objeto services según la opción seleccionada
  const filteredService = services.find(
    (service) => service.Frequency === selectedOption
  );

  // WARNING: Se asume que el primer puesto es el regular
  let cost = "0.00";
  if (filteredService && filteredService.Regular.length > 0) {
    cost = filteredService.Regular[0].Cost
      ? filteredService.Regular[0].Cost.toFixed(2)
      : "0.00";
  }

  return (
    <>
      <Box display="flex" justifyContent={"center"} sx={{ mt: 2 }}>
        <DayPrice cost={cost} />
      </Box>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <Divider variant="middle" />
        <PriceItemList
          type={"Regular"}
          details={filteredService ? filteredService.Regular : []}
        />
        <YardFeatures featuresList={itemYard.pYardInformation} />
        {/* <PriceItemList
          type={"Overage"}
          details={filteredService ? filteredService.Overage : []}
        /> */}
      </List>
    </>
  );
};

export default PricesSection;
