import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Container from "@mui/material/Container";
import ProductCardsContainer from "./containers/ProductCardsContainer/ProductCardsContainer";
import GeneralProductsContainer from "./containers/GeneralProductsContainer/GeneralProductsContainer";
import ChassisProductsContainer from "./containers/ChassisProductsContainer/ChassisProductsContainer";

import theme from "./styles/theme";
import "./styles/global.css";

export default function Root(props) {
  const router = createBrowserRouter([
    {
      path: "/landing-page/products",
      element: <GeneralProductsContainer />,
    },
    {
      path: "/landing-page/products/yard-storage",
      element: <ProductCardsContainer />,
    },
    {
      path: "/landing-page/products/chassis-rental",
      element: <ChassisProductsContainer />,
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Container className="ProductPicker-container-root" maxWidth={false}>
        <RouterProvider router={router} />
      </Container>
    </ThemeProvider>
  );
}
