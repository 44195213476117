import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import Typography from "@mui/material/Typography";

import { formatAsUSD } from "../../../utils/moneyUtils";

const PriceItemList = ({ type, details }) => {
  let title, icon;
  if (type === "Regular") {
    title = "Regular Price";
    icon = <LocalShippingOutlinedIcon />;
  } else if (type === "Overage") {
    title = "Overage Price";
    icon = <LocalAtmOutlinedIcon />;
  }

  return (
    <>
      <Divider variant="middle" />
      <ListItem>
        <ListItemIcon
          sx={{
            alignSelf: "flex-start",
            justifyContent: "flex-end",
            minWidth: "35px",
            mt: 1,
            mr: 1,
            color: "secondary.main",
          }}
        >
          {icon}
        </ListItemIcon>
        <Box sx={{ mt: 1, mb: 2 }}>
          <Typography
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "text.main",
            }}
          >
            {title}
          </Typography>

          {details.map((detail, index) => (
            <Typography
              key={index}
              style={{
                fontSize: 18,
                fontWeight: "regular",
                color: "text.main",
              }}
            >
              {formatAsUSD(detail.Cost)} = {detail.Description}
            </Typography>
          ))}
        </Box>
      </ListItem>
    </>
  );
};

export default PriceItemList;
