import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#1578FB",
      light: "#125BB9",
      dark: "#22224F",
    },
    secondary: {
      main: "#FC4818",
    },
    error: {
      main: "#FF0000",
    },
    warning: {
      main: "#FFD700",
    },
    info: {
      main: "#00BFFF",
    },
    success: {
      main: "#008000",
    },
    background: {
      default: "#F2F5F7",
      paper: "#FFFFFF",
    },
    white: {
      main: "#FFFFFF",
    },
    text: {
      primary: "#2A2B2C",
      secondary: "#FFFFFF",
      disabled: "#C5CAD0",
    },
    divider: "#F2F5F7",
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
});

export default theme;
