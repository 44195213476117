import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import "./ChassisFeatures.css";

const ChassisFeatures = ({ featuresList }) => {
  if (!featuresList || featuresList.length === 0) {
    return <div>No hay ubicaciones disponibles.</div>;
  }

  return (
    <>
      <section className="ProductPicker-ChassisFeatures-container">
        <Typography
          variant="h2"
          className="ProductPicker-ChassisFeatures-text-title-list"
        >
          Chassis Features
        </Typography>
        <Grid container spacing={1}>
          <Grid lg={12} md={12} sm={12} xs={12} container>
            {featuresList.map((feature) => (
              <Grid xs={6} key={feature}>
                <Typography
                  key={feature}
                  className="ProductPicker-ChassisFeatures-array-list"
                >
                  {feature}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid lg={4} md={4} sm={0} xs={0}></Grid>
        </Grid>
      </section>
    </>
  );
};
export default ChassisFeatures;
