import axios from "axios";

export const getPrices = async (params) => {
  try {
    const response = await axios.get(process.env.REACT_APP_GET_PRICES_URL, {
      params,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIMANAGEMENT_HEADER,
      },
    });
    return response.data.Data;
  } catch (error) {
    console.error("Error fetching prices:", error);
    return [];
  }
};
