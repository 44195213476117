import { Paper, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import "./AvailableProductCard.css";

const AvailableProduct = ({
  product,
  productDescription,
  functionToUse,
  urlOfProduct,
  imageProduct,
}) => {
  let imagePath;
  try {
    imagePath = process.env.REACT_APP_IMAGES_URL + imageProduct;
  } catch (error) {}

  return (
    <Paper
      elevation={4}
      className="AvailableProduct-paper-container"
      onClick={(e) => functionToUse(urlOfProduct)}
    >
      <Grid container className="AvailableProduct-grid-container">
        <Grid item xs={12} className="AvailableProduct-grid-image-container">
          <div className="AvailableProduct-disabled-image-container">
            <img
              className="AvailableProduct-disabled-image"
              src={imagePath}
              alt="Coming Soon"
            />
          </div>
          <Typography className="AvailableProduct-product-title-card">
            {product}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display={"contents"}
          justifyContent={"center"}
          minHeight={"fit-content"}
        >
          {productDescription.map((item, index) => (
            <Typography
              key={index}
              className="AvailableProduct-product-text-card"
            >
              ✔ {item}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AvailableProduct;
