import axios from "axios";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIMANAGEMENT_HEADER,
  "Content-Type": "application/json",
};
export const getChassisPrices = async () => {
  try {
    const config = {
      headers,
      method: "GET",
      url: process.env.REACT_APP_GET_CHASSIS_PRICES,
    };
    const response = await axios(config);
    return response.data.Data;
  } catch (error) {
    console.error("Error fetching prices:", error);
    return error;
  }
};
